<template>
  <div><div class="title">saas商城软件说明</div><div style="text-align: center; line-height: 20px; margin-top: 10px;">
    发布时间：2022-04-11 20:20:37
  </div><div style="font-size: 14px;"><p>&nbsp;</p>
    <p><img src="https://www.houniao.hk/upload/image/20190807/20190807120606_97761.jpg" alt="" width="950" data-ke-src="/upload/image/20190807/20190807120606_97761.jpg">​</p></div></div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/11/15 10:51
 * @version 1.0
 * @description
 */
export default {
  name: 'SoftwareDescriptionOfSaaSMall'
}
</script>

<style scoped>

</style>
